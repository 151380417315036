
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import useNotification from "@/components/useNotification";
//import { useToast } from "primevue/usetoast";

export default defineComponent({
  name: "dashboard",
  components: {},
  setup() {
    //    const toast = useToast();
    const store = useStore();
    const notification = useNotification();

    store.state.ReportModule.lastSixMonthReport?.value.map(
      (x) => x.performanceReport.orderPrice
    );

    onMounted(() => {
      setCurrentPageTitle("Dashboard");
    });
    const currentYear = ref(new Date().getFullYear());
    const basicData = ref({
      labels: store.state.ReportModule.lastSixMonthReport?.value
        .filter((f) => f.year == new Date().getFullYear())
        .map((x) => x.monthName),
      datasets: [        
        {
          label: currentYear.value - 1,
          backgroundColor: "#a467dc",
          data: store.state.ReportModule.lastSixMonthReport?.value
            .filter((f) => f.year == (currentYear.value - 1))
            .map((x) => x.performanceReport.orderPrice),
        },
        {
          label: currentYear,
          backgroundColor: "#67b7dc",
          data: store.state.ReportModule.lastSixMonthReport?.value
            .filter((f) => f.year == new Date().getFullYear())
            .map((x) => x.performanceReport.orderPrice),
        }
      ],
    });
    return {
      basicData,
      store,
    };
  },
});
